

export default function SiteFooter() {

  
  return (
    <footer>
      © 2022 Magali Location Vaisselle. Conception & Réalisation du site, Cédric Tressous.
    </footer>
  );
}